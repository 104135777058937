/* eslint-disable prefer-template */
import React, {
    useEffect, useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import API from '../services/API';
import Loading from '../components/elements/Loading';
import Hero from '../components/Hero';
import WhyComponent from '../components/WhyComponent';
import FeaturedSuppliers from '../components/FeaturedSuppliers';
import PromoList from '../components/PromoList';
import ButtonInverted from '../components/elements/ButtonInverted';
import 'react-toastify/dist/ReactToastify.css';


function Promotions() {
    const curYear = new Date().getFullYear();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const getCategories = () => {
        setLoading(true);
        API.getPromoCategoryCounts().then((result) => {
            if (!result || result.error) { setData([]); } else setData(result);
            setLoading(false);
        });
    };
    useEffect(() => {
        getCategories();
    }, []);

    function goToAnchor(anchor) {
        const loc = document.location.toString().split('#')[0];
        document.location = loc + '#' + anchor;
        return false;
    }
    return (
        <>
            <Helmet>
                <title>{`Special Travel Offers - ${curYear} Travel Agent Promotions  | Travel Leaders`}</title>
                <meta name="title" content={`Special Travel Offers - ${curYear} Travel Agent Promotions  | Travel Leaders`} />
                <meta name="description" content="See a list of all current travel promotions offered by Travel Leaders" />
            </Helmet>
            <Hero title="Our special offers include expert travel advice" imageFile="hero-promotions.webp" />
            <div className=" max-w-screen-2xl mx-auto">
                <div className="min-h-screen">
                    <div className="-mt-44 relative left-0 md:mx-20 right-0 z-10">
                        <div className="bg-white shadow-lg md:shadow-xl rounded px-8 py-6 z-40 text-center">
                            <div className="mb-6 text-lg">
                                Search for travel specials by the following categories, then contact one of our expert travel agents to learn more.
                            </div>
                            <div className="">
                                    {data.length > 0
                                        && (
                                            <div className="flex flex-col md:flex-row gap-4 sm:gap-8 justify-between">
                                                <ToastContainer />
                                                {!loading && (
                                                    <>
                                                            {data.map((x) => (
                                                                <React.Fragment key={x.promoType}>
                                                                    {(x.promoType.toLowerCase() === 'cruise') ? (
                                                                        <div className="flex flex-col justify-center flex-1">
                                                                            <ButtonInverted
                                                                                title="Cruise Specials"
                                                                                onClick={() => goToAnchor('cruise')}
                                                                            />
                                                                        </div>
                                                                    ) : ''}
                                                                    {(x.promoType.toLowerCase() === 'hotel') ? (
                                                                        <div className="flex flex-col justify-center flex-1">
                                                                            <ButtonInverted
                                                                                title="Hotel Offers"
                                                                                onClick={() => goToAnchor('hotel')}
                                                                            />
                                                                        </div>
                                                                    ) : ''}
                                                                    {(x.promoType.toLowerCase() === 'tour') ? (
                                                                        <div className="flex flex-col justify-center flex-1">
                                                                            <ButtonInverted
                                                                                title="Vacation Packages"
                                                                                onClick={() => goToAnchor('vacation')}
                                                                            />
                                                                        </div>
                                                                    ) : ''}
                                                                    {(x.promoType.toLowerCase() === 'rail') ? (
                                                                        <div className="flex flex-col justify-center flex-1">
                                                                            <ButtonInverted
                                                                                title="Rail Deals"
                                                                                onClick={() => goToAnchor('rail')}
                                                                            />
                                                                        </div>
                                                                    ) : ''}
                                                                    {(x.promoType.toLowerCase() === 'car') ? (
                                                                        <div className="flex flex-col justify-center flex-1">
                                                                            <ButtonInverted
                                                                                title="Car Specials"
                                                                                onClick={() => goToAnchor('car')}
                                                                            />
                                                                        </div>
                                                                    ) : ''}
                                                                </React.Fragment>
                                                            ))}
                                                    </>
                                                )}

                                            </div>
                                        )}
                            </div>
                        </div>
                    </div>

                    <div id="cruise">
                        <PromoList
                            promoType="Cruise"
                            promoTitle="Cruise Specials"
                            introTxt="Our agents are avid cruisers who know these ships and can answer all your questions."
                        />
                    </div>

                    <div className="mt-10 md:mt-20 bg-light-blue">
                        <div className="md:relative md:-top-8">
                            <WhyComponent displayStats="" />
                        </div>
                    </div>
                    <div id="vacation">
                        <PromoList
                            promoType="Tour"
                            promoTitle="Vacation Packages"
                            introTxt="Our agents have expert knowledege on all types of vacation packages and can help you tailor the perfect vacation of your dreams."
                        />
                    </div>
                    <div>
                        <FeaturedSuppliers />
                    </div>
                    <div id="hotel">
                        <PromoList
                            promoType="Hotel"
                            promoTitle="Hotel Offers"
                            introTxt="Our agents are hotel and resort specialists who can answer any questions you may have about these properties and more."
                        />
                    </div>
                    <div id="rail">
                        <PromoList
                            promoType="Rail"
                            promoTitle="Rail Deals"
                            introTxt="Whether you are looking for a quick train getaway, independent rail journeys or a legendary rail experience, our expert travel agents are here for you."
                        />
                    </div>
                    <div id="car">
                        <PromoList
                            promoType="Car"
                            promoTitle="Car Specials"
                            introTxt="From weekend getaways to week-long adventures, find the perfect special deal for your road or business trip."
                        />
                    </div>

                </div>
            </div>
            {loading && <Loading />}
        </>
    );
}

export default Promotions;
