/* eslint-disable react/prop-types */
import React from 'react';
import { IoLocationSharp, IoCheckmarkCircleSharp } from 'react-icons/io5';

const BookedVisitedInfo = ({ className, agent }) => {
    if (!agent?.bookedVisited?.location) {
        return null; // Render nothing if location is not set
    }

    return (
        <div className={className}>
            {agent.bookedVisited.beenThere > 0 && (
                <div className="flex items-start">
                    <div className="text-4xl pr-4">
                        <IoLocationSharp />
                    </div>
                    <div className="font-bold capitalize">
                        {`Traveled to ${agent.bookedVisited.location} ${agent.bookedVisited.beenThere > 25
                                ? '25+ times'
                                : agent.bookedVisited.beenThere > 2
                                    ? `${agent.bookedVisited.beenThere} times`
                                    : ''
                            }`}
                        <span className="block text-xs font-normal mb-4">
                            {`Personally visited ${agent.bookedVisited.location} and can offer firsthand knowledge and insights for planning the perfect trip.`}
                        </span>
                    </div>
                </div>
            )}
            {agent.bookedVisited.bookedIt > 0 && (
                <div className="flex items-start">
                    <div className="text-4xl pr-4">
                        <IoCheckmarkCircleSharp />
                    </div>
                    <div className="font-bold capitalize">
                        {`Booked ${agent.bookedVisited.bookedIt > 25
                                ? `25+ ${agent.bookedVisited.location} trips`
                                : agent.bookedVisited.bookedIt > 2
                                    ? `${agent.bookedVisited.bookedIt} ${agent.bookedVisited.location} trips`
                                    : agent.bookedVisited.location
                            }`}
                        <span className="block text-xs font-normal mb-4">
                            {`Successfully booked ${agent.bookedVisited.bookedIt > 2
                                    ? `${agent.bookedVisited.bookedIt} trips`
                                    : ''
                                } to ${agent.bookedVisited.location} showcasing expertise in planning unforgettable experiences tailored to clients' needs.`}
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BookedVisitedInfo;
