/* eslint-disable prefer-template */
/* eslint-disable no-lonely-if */
import React, {
    useEffect, useRef, useState,
} from 'react';
import moment from 'moment';
import { useLocation, useParams, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Modal from 'react-modal';
import Cookies from 'universal-cookie';
import { IoClose } from 'react-icons/io5';
import StarRatings from 'react-star-ratings';
import parser from 'html-react-parser';
import StickyBox from 'react-sticky-box';
import {
    callMe, CONTACT_METHOD_EMAIL, CONTACT_METHOD_PHONE, BLOG_IMAGE_URL, modalCustomStyles, randomIntFromInterval,
} from '../utils';
import useAppStore from '../contexts/useAppStore';
import API from '../services/API';
import Loading from '../components/elements/Loading';
import AgentLabelBlog from '../components/AgentLabelBlog';
import WhyComponent from '../components/WhyComponent';
import ArticlesList from '../components/ArticlesList';
import EmailForm from '../components/EmailFormBlogs';
import PhoneForm from '../components/PhoneForm';
import Button from '../components/elements/Button';
import ButtonInverted from '../components/elements/ButtonInverted';
import BreadCrumb from '../components/BreadCrumb';

function Blog() {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [mainAgent, setMainAgent] = useState();
    const [agentData, setAgentData] = useState([]);
    const [blogCategory, setBlogCategory] = useState();
    const [data, setData] = useState();
    const [links, setLinks] = useState([]);
    const agentRef = useRef(null);
    const [selectedAgent, setSelectedAgent] = useState();
    const executeScroll = (ref, block = 'start') => {
        ref.current.scrollIntoView({ behavior: 'smooth', block });
    };
    const [showPinBar, setShowPinBar] = useState(false);
    function useQuery() {
        return new URLSearchParams(useLocation().search.toLowerCase());
    }
    const query = useQuery();
    const [blogAttribute, setBlogAttribute] = useState('');
    const [category, setCategory] = useState(query.get('category') || null);
    const [nav, setnav] = useState(query.get('nav') || null);
    const myIp = query.get('ip') || null;
    const [preview, setPreview] = useState(query.get('preview') || 'false');
    const [agentId, setAgentId] = useState(query.get('agentid') || 0);
    const [agencyId, setAgencyId] = useState(query.get('agencyid') || 0);
    const [isSpotlightAgent, setIsSpotlightAgent] = useState(0);
    const [mobAgentPosition, setMobAgentPosition] = useState('0px');
    const [canonicalURL, setCanonicalURL] = useState('');
    const agentCookie = new Cookies();
    let returnAgents = 3;
    let useAgencyId = agencyId;
    let useAgentId = agentId;
    // Check/Use AgentId if in param/cookie
    if (agentId !== 0) {
        returnAgents = 1;
        agentCookie.set('ckAgentId', agentId,
            {
                expires: new Date(Date.now() + (60 * 60 * 24 * 1000)),
                path: '/',
                sameSite: 'lax',
            });
    }

    if (agentCookie.get('ckAgentId')) {
        useAgentId = agentCookie.get('ckAgentId');
        returnAgents = 1;
    }
    //Check/Use AgencyId if in param/cookie
    if (agencyId !== 0) {
        agentCookie.set('ckAgencyId', agencyId,
            {
                expires: new Date(Date.now() + (60 * 60 * 24 * 1000)),
                path: '/',
                sameSite: 'lax',
            });
    }

    if (agentCookie.get('ckAgencyId')) {
        useAgencyId = agentCookie.get('ckAgencyId');
    }

    const {
        setIsEmailFormModalOpen, setIsPhoneFormModalOpen, isEmailFormModalOpen, isPhoneFormModalOpen, closeFormModal,
    } = useAppStore();

    const getBlog = () => {
        setLoading(true);
        API.getBlog({ storyTitle: '', storyId: id, preview }).then((result) => {
            if (result.responseStatus === 1) {
                if (result.data.storyId > 0) {
                    setData(result.data);
                }
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    const userLocationCookie = new Cookies();

    const getMatchedAgentsUserLocation = async () => {
        API.getIpLocation({
            myIp,
        }).then((ipResult) => {
            if (ipResult.responseStatus === 1) {
                /*SET THE COOKIE*/
                userLocationCookie.set('userLocation', JSON.stringify(ipResult.data),
                    {
                        expires: new Date(Date.now() + (60 * 60 * 24 * 1000 * 7)),
                        path: '/',
                        sameSite: 'lax',
                    });
                /*GET CLOSEST AGENTS*/
                API.agentMatchToInterest({
                    id: useAgentId,
                    agencyId: useAgencyId,
                    destination: data.destinations.map((x) => x.destinationId).join(',') || 0,
                    interest: data.interests.map((x) => x.interestId).join(',') || 0,
                    supplier: data.suppliers.map((x) => x.supplierId).join(',') || 0,
                    latitude: ipResult.data.latitude,
                    longitude: ipResult.data.longitude,
                    returnAgents,
                    locality: ipResult.data.country_code,
                    promotionId: 0,
                }).then(async (agentResult) => {
                    if (agentResult.responseStatus === 1) {
                        setAgentData(agentResult.data);
                    } else {
                        toast(agentResult.error.message);
                    }
                });
            } else {
                toast(ipResult.error.message);
            }
        });
    };

    const getMatchedAgents = async () => {
        API.agentMatchToInterest({
            id: useAgentId,
            agencyId: useAgencyId,
            destination: data.destinations.map((x) => x.destinationId).join(',') || 0,
            interest: data.interests.map((x) => x.interestId).join(',') || 0,
            supplier: data.suppliers.map((x) => x.supplierId).join(',') || 0,
            latitude: userLocationCookie.get('userLocation').latitude,
            longitude: userLocationCookie.get('userLocation').longitude,
            returnAgents,
            locality: userLocationCookie.get('userLocation').country_code,
            promotionId: 0,
        }).then(async (result) => {
            if (result.responseStatus === 1) {
                setAgentData(result.data);
            } else {
                toast(result.error.message);
            }
        });
    };

    useEffect(() => {
        if (data) {
            const base = [{
                label: 'Home',
                url: '/',
            },
            {
                label: 'Travel Information',
                url: '/travelbetter',
            }];
            if (category) {
                const categoryArr = category.split('~');
                const [categoryType, categoryName] = categoryArr;
                let text = `${categoryName}`;
                if (category.toLowerCase() === 'travel tips') {
                    text = 'Travel tips and Ideas';
                }

                base.push({
                    label: text,
                    url: `/travelbetter_category?category=${category}`,
                });
            }
            base.push({
                label: `${data.title}`,
            });

            setLinks(base);
        }
    }, [category, data]);

    useEffect(() => {
        if (data) {
            if (data.categories && data.categories.length > 0) {
                const index = randomIntFromInterval(0, data.categories.length - 1);
                setBlogCategory(data.categories[index]);
            }
            if (data.spotlightAgents && data.spotlightAgents.length > 0 && useAgentId === 0 && useAgencyId === 0) {
                const index = randomIntFromInterval(0, data.spotlightAgents.length - 1);
                setMainAgent(data.spotlightAgents[index]);
                setIsSpotlightAgent(1);
            } else {
                if ((!userLocationCookie.get('userLocation')) || (myIp !== null)) {
                    getMatchedAgentsUserLocation();
                } else if ((userLocationCookie.get('userLocation')) && (myIp === null)) {
                    getMatchedAgents();
                }
            }
        }
    }, [data]);

    useEffect(() => {
        if (mainAgent && data) {
            if (data.destinations.length > 0) {
                setBlogAttribute(data.destinations[0].destination1);
            } else if (data.interests.length > 0) {
                setBlogAttribute(data.interests[0].interest1);
            } else if (data.suppliers.length > 0) {
                setBlogAttribute(data.suppliers[0].SupplierName);
            }
        }
    }, [mainAgent, data]);

    useEffect(() => {
        getBlog();
        window.scrollTo(0, 0);
    }, []);

    const myStyle = {
        backgroundColor: '#022439',
        bottom: mobAgentPosition,
        position: 'fixed',
    };

    const emailMe = (agent) => {
        setSelectedAgent(agent);
        setIsEmailFormModalOpen(true);
    };

    const closeModal = () => {
        closeFormModal();
    };

    const tagCss = 'block text-red-600 text-xs md:text-sm uppercase bg-white m-1 md:m-2 text-center border-2 border-red-500 rounded-full p-1 md:p-2 cursor-pointer hover:text-red-900 hover:border-red-900';

    const listenToScroll = () => {
        const heightToHideFrom = 450;

        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

        if (winScroll > heightToHideFrom) { setShowPinBar(true); } else { setShowPinBar(false); }
    };
    useEffect(() => {
        window.addEventListener('scroll', listenToScroll);
        return () => {
            window.removeEventListener('resize', listenToScroll);
        };
    }, []);

    const dateConverter = (startDate, endDate) => {
        const newStartDate = new Date(startDate);
        const newEndDate = new Date(endDate);
        const oneDay = 1000 * 60 * 60 * 24;
        const result = Math.ceil((newEndDate.getTime() - newStartDate.getTime()) / (oneDay));
        if (result < 0) { return 0; }
        return result;
    };
    // MANUALLY SET CANONICAL
    useEffect(() => {
        let newCanonicalURL = `${window.location.origin}${window.location.pathname}`;
        const storyIdToCanonicalMap = {
            8812: 'https://internovatravel.com/article/costa-rican-cuisine-fresh-mild-and-flavorful',
            8813: 'https://internovatravel.com/article/park-hyatt-milan-a-luxurious-journey-through-time',
            8816: 'https://internovatravel.com/article/bright-lights-suite-city',
            8817: 'https://internovatravel.com/article/season-to-taste',
            8818: 'https://internovatravel.com/article/oltre-go-with-the-flow',
            ///DEV TEST
            8: 'https://internovatravel.com/category/hotels/8-must-visit-hotels-for-a-fall-foliage-getaway',
            //QA TEST
            260: 'https://internovatravel.com/article/perfect-day-in-seattle',
        };
        if (storyIdToCanonicalMap[id]) {
            newCanonicalURL = storyIdToCanonicalMap[id];
        }
            setCanonicalURL(newCanonicalURL);
    }, []);
    return (

        <>

            <ToastContainer />

            {data
                && (
                    <>
                        <Helmet>
                            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/pure/0.6.0/pure-min.css" />
                            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/pure/0.6.0/grids-responsive-min.css" />
                            <link rel="preconnect" href="https://fonts.googleapis.com" />
                            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
                            <link href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap" rel="stylesheet" />
                            <title>
                                {data.title}
                                {' '}
                                | Travelleaders.com
                            </title>
                            <meta name="title" content={`${data.title}`} />
                            <meta
                                name="description"
                                content={`${data.shortDescription.substring(0, 165)}`}
                            />
                        {/*FACEBOOK META */}
                            <meta
                                property="og:title"
                                content={data.title}
                            />
                            <meta
                                property="og:description"
                                content={data.images.find((x) => x.fileType === 'FB') !== undefined ? (
                                    `${data.images.find((x) => x.fileType === 'FB').description}`
                                ) : (
                                        `${data.shortDescription}`
                                    )}
                            />
                            <meta
                                property="og:image"
                                content={data.images.find((x) => x.fileType === 'FB') !== undefined ? (
                                    `${BLOG_IMAGE_URL()}${data.images.find((x) => x.fileType === 'FB').fileName}`
                                ) : (
                                        `${BLOG_IMAGE_URL()}${data.images.find((x) => x.fileType === 'HeroImage').fileName}`
                                    )}
                            />
                            <meta property="og:type" content="article" />
                            <meta
                                property="og:url"
                                content={`${window.location.origin}${window.location.pathname}`}
                            />
                            <meta property="og:site_name" content="Travel Leaders" />
                            <meta property="fb:admins" content="631463929" />
                            <meta property="fb:app_id" content="130781060338611" />
                        {/*TWITTER META */}
                            <meta name="twitter:card" content="summary_large_image" />
                            <meta name="twitter:site" content="@travelleaders" />
                            <meta name="twitter:creator" content="@travelleaders" />
                            <meta name="twitter:title" content={`${data.title}`} />
                            <meta
                                name="twitter:description"
                                content={data.images.find((x) => x.fileType === 'TW') !== undefined ? (
                                    `${data.images.find((x) => x.fileType === 'TW').description}`
                                ) : (
                                        `${data.shortDescription}`
                                    )}
                            />
                            <meta
                                name="twitter:image"
                                content={data.images.find((x) => x.fileType === 'TW') !== undefined ? (
                                    `${BLOG_IMAGE_URL()}${data.images.find((x) => x.fileType === 'TW').fileName}`
                                ) : (
                                        `${BLOG_IMAGE_URL()}${data.images.find((x) => x.fileType === 'HeroImage').fileName}`
                                    )}
                            />
                            <link rel="canonical" href={canonicalURL} />
                        </Helmet>
                        <div className="w-full relative">
                            <div className="ml-2 mt-4 absolute z-50 hidden md:block">
                            {nav === null ? <BreadCrumb links={links} inverted /> : ''}
                            </div>
                            <img
                                src={`${BLOG_IMAGE_URL()}${data.images.find((x) => x.fileType === 'HeroImage').fileName}`}
                                className="w-full  h-96 object-cover"
                                alt=""
                                style={{ height: '35rem' }}
                                loading="lazy"
                            />
                        </div>
                        <div className=" max-w-screen-xl mx-auto">
                            <div className="min-h-screen">

                                <div className="flex flex-col gap-10 items-center">
                                <div className="relative -top-56 -mb-56 rounded border-8 border-white shadow-lg">
                                    <img
                                        src={`${BLOG_IMAGE_URL()}${data.images.find((x) => x.fileType === 'ThumbImage').fileName}`}
                                        className="mx-auto w-72"
                                        alt=""
                                        loading="lazy"
                                    />
                                </div>
                                <div className="text-center md:text-left font-semibold flex justify-center flex-col mx-4">
                                    <div className="text-center uppercase mb-8">
                                        {dateConverter(data.datePublished, new Date()) < 550 ? moment(data.datePublished).format('l') : ''}
                                        {' '}
                                        &nbsp;&nbsp;&nbsp;
                                        by
                                        {' '}
                                        <Link to={`/travelbetter_authors/?author=${data.author}`} className="tl-red hover:text-red-900 underline">
                                            {data.author}
                                        </Link>
                                    </div>
                                    <h1 className="text-2xl md:text-5xl uppercase font-normal tl-cyan text-center">
                                        {data.title}
                                    </h1>
                                </div>
                                    <div className="flex flex-col gap-10">
                                        <div className="flex flex-col md:flex-row gap-2 justify-center mx-4 pb-4 md:mx-40 border-b border-double">
                                            <div className="flex-1 tracking-wider leading-9 text-lg md:text-xl font-light">
                                                <div className="w-full font-bold">
                                                    {parser(data.shortDescription)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="min-h-screen">
                                        {/*This is the comment sticky box*/}
                                        {data && (
                                            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                                <div className="flex flex-col gap-2 items-center px-4  md:px-16 divBlogStory">
                                                    {parser(data.story)}
                                                </div>
                                                {agentData.slice(0, 1).map((primaryAgent) => (
                                                    <>
                                                        <StickyBox
                                                            key={primaryAgent.agentId}
                                                            offsetTop={200}
                                                            offsetBottom={0}
                                                            className="hidden-tw md:block"
                                                            style={
                                                                {
                                                                    backgroundColor: '#022439',
                                                                    color: '#FFFFFF',
                                                                    zIndex: '10',
                                                                    minWidth: '150px',
                                                                    minHeight: '250px',
                                                                    marginTop: '20px',
                                                                    marginBottom: '20px',
                                                                    marginRight: '20px',
                                                                    borderRadius: '5px',
                                                                    boxShadow: '3px 3px 5px 0px #7C7C7C',
                                                                }
                                                            }
                                                        >
                                                            <div className="w-36 h-36 uppercase text-xs text-center p-2 m-auto">
                                                                Plan your next trip with
                                                                <div className="h-20 w-20 mx-auto rounded-full overflow-hidden my-2">
                                                                    <img
                                                                        alt=""
                                                                        src={primaryAgent.agentPhotoFileName}
                                                                        className=""
                                                                        loading="lazy"
                                                                    />
                                                                </div>
                                                                <div className="font-bold">{primaryAgent.firstName}</div>
                                                                <div className="capitalize">
                                                                    {primaryAgent.city}
                                                                    {', '}
                                                                    {primaryAgent.state}
                                                                </div>
                                                                <div className="flex flex-col md:flex-row gap-1 md:gap-1 w-full mt-2 justify-center">
                                                                    {primaryAgent.agentConnections.length > 0 && (
                                                                        <>
                                                                            {primaryAgent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_EMAIL) && (
                                                                                <div className="w-full md:w-1/2">
                                                                                    <Button
                                                                                        title="EMAIL"
                                                                                        onClick={() => emailMe(primaryAgent)}
                                                                                        padding="p-1"
                                                                                        fontSize="text-xs"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                            {primaryAgent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_PHONE) && (
                                                                                <div className="w-full md:w-1/2">
                                                                                    <Button
                                                                                        title="CALL"
                                                                                        onClick={() => callMe(primaryAgent, () => {
                                                                                            setSelectedAgent(primaryAgent);
                                                                                            setIsPhoneFormModalOpen(true);
                                                                                        })}
                                                                                        padding="p-1"
                                                                                        fontSize="text-xs"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                    {/*if agent id is not found use generic approver email*/}
                                                                    {primaryAgent.agentId === 0 ? (
                                                                        <>
                                                                            <div className="w-full md:w-1/2">
                                                                                <Button
                                                                                    title="EMAIL"
                                                                                    onClick={() => emailMe(primaryAgent)}
                                                                                    padding="p-1"
                                                                                    fontSize="text-xs"
                                                                                />
                                                                            </div>
                                                                            <div className="w-full md:w-1/2">
                                                                                <Button
                                                                                    title="CALL"
                                                                                    onClick={() => callMe(primaryAgent, () => {
                                                                                        setSelectedAgent(primaryAgent);
                                                                                        setIsPhoneFormModalOpen(true);
                                                                                    })}
                                                                                    padding="p-1"
                                                                                    fontSize="text-xs"
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    ) : ''}
                                                                </div>
                                                                <div className="w-full">
                                                                    {(useAgentId === 0) && (useAgencyId === 0) ? (
                                                                        <Link to={`/agent/${primaryAgent.agentId}`} className="bg-tl-red rounded-full p-1 text-white text-xs hover:bg-red-900 block my-2">VIEW BIO</Link>
                                                                    ) : ''}
                                                                </div>
                                                            </div>
                                                        </StickyBox>
                                                        <div className={[showPinBar ? 'opacity-100' : 'opacity-0 hidden-tw', 'fixed bottom-0 w-full text-white p-4 text-center z-10 hiddenAgent'].join(' ')} style={myStyle}>
                                                            <div className="-mt-14 border h-20 w-20 mx-auto rounded-full overflow-hidden my-2">
                                                                <img
                                                                    alt=""
                                                                    src={primaryAgent.agentPhotoFileName}
                                                                    className=""
                                                                    loading="lazy"
                                                                />
                                                            </div>
                                                            <div className="font-bold uppercase">Let&apos;s Plan Your Next Trip</div>
                                                            <div className="font-bold">
                                                                <Link to={`/agent/${primaryAgent.agentId}`} className="bg-tl-red rounded-full p-1 text-white text-xs hover:bg-red-900 block my-2">
                                                                    {primaryAgent.firstName}
                                                                </Link>
                                                            </div>
                                                            <div className="flex flex-row gap-2 w-full mt-2 justify-center">
                                                                {primaryAgent.agentConnections.length > 0 && (
                                                                    <>
                                                                        {primaryAgent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_EMAIL) && (
                                                                            <div className="w-1/2">
                                                                                <Button
                                                                                    title="EMAIL"
                                                                                    onClick={() => emailMe(primaryAgent)}
                                                                                    padding="p-1"
                                                                                    fontSize="text-xs"
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        {primaryAgent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_PHONE) && (
                                                                            <div className="w-1/2">
                                                                                <Button
                                                                                    title="CALL"
                                                                                    onClick={() => callMe(primaryAgent, () => {
                                                                                        setSelectedAgent(primaryAgent);
                                                                                        setIsPhoneFormModalOpen(true);
                                                                                    })}
                                                                                    padding="p-1"
                                                                                    fontSize="text-xs"
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                )}
                                                                {/*if agent id is not found use generic approver email*/}
                                                                {primaryAgent.agentId === 0 ? (
                                                                    <>
                                                                        <div className="w-1/2">
                                                                            <Button
                                                                                title="EMAIL"
                                                                                onClick={() => emailMe(primaryAgent)}
                                                                                padding="p-1"
                                                                                fontSize="text-xs"
                                                                            />
                                                                        </div>
                                                                        <div className="w-1/2">
                                                                            <Button
                                                                                title="CALL"
                                                                                onClick={() => callMe(primaryAgent, () => {
                                                                                    setSelectedAgent(primaryAgent);
                                                                                    setIsPhoneFormModalOpen(true);
                                                                                })}
                                                                                padding="p-1"
                                                                                fontSize="text-xs"
                                                                            />
                                                                        </div>
                                                                    </>
                                                                ) : ''}
                                                            </div>
                                                        </div>
                                                    </>
                                                ))}
                                            </div>
                                        )}
                                        {/*end of agent sticky box*/}
                                        </div>
                                    {isSpotlightAgent === 1 && mainAgent && (
                                        <>
                                            <AgentLabelBlog agent={mainAgent} blog={data} blogAttribute={blogAttribute} />
                                        </>
                                    )}
                                    {isSpotlightAgent === 0 && (
                                        <div className="w-full pb-8 px-4 bg-light-blue relative z-20">
                                            <div className="mx-4 md:mx-40 py-10 text-center">
                                                <h2 className="tl-cyan uppercase text-2xl md:text-5xl  tracking-wide">We are here to help you turn your travel dreams into reality. contact us today!</h2>
                                            </div>
                                            <div className={`grid grid-cols-1 md:grid-cols-${returnAgents} gap-4 mx-4 lg:mx-40`}>
                                                {agentData.map((featAgent, index) => (
                                                    <div
                                                        className="flex flex-col bg-white shadow-lg p-4 rounded text-center"
                                                        key={featAgent.agentId}
                                                    >
                                                        <div className="flex-grow">
                                                            <div className="mx-auto w-36 h-36 overflow-hidden object-cover border rounded-lg">
                                                                <Link to={`/agent/${featAgent.agentId}`} className="hover:opacity-75" style={(useAgentId === 0) && (useAgencyId === 0) ? { margin: '0' } : { pointerEvents: 'none' }}>
                                                                    <img
                                                                        src={featAgent.agentPhotoFileName}
                                                                        alt={`${featAgent.firstName} ${featAgent.lastName}`}
                                                                        className="min-w-full"
                                                                        loading="lazy"
                                                                    />
                                                                </Link>
                                                            </div>
                                                            <div className="w-full text-xl tl-red font-semibold mt-2 mb-1 uppercase">
                                                                {`${featAgent.firstName} ${featAgent.lastName}`}
                                                            </div>
                                                            <div className="w-full text-sm text-gray-500 mb-1">
                                                                    {`${featAgent.city}, ${featAgent.state}`}
                                                            </div>
                                                            <div className="w-full text-sm mb-1">
                                                                {featAgent.websiteDisplayTitle}
                                                            </div>
                                                            <div className="mb-1 h-8">
                                                                {featAgent.agentRating.rating > 0 && (
                                                                    <StarRatings
                                                                        rating={featAgent.agentRating.rating}
                                                                        starRatedColor="#0A7790"
                                                                        numberOfStars={5}
                                                                        name="rating"
                                                                        starDimension="28px"
                                                                        starSpacing="4px"
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="mb-1 text-red uppercase">
                                                                {(useAgentId === 0) && (useAgencyId === 0) ? (
                                                                    <Link to={`/agent/${featAgent.agentId}`} className="tl-red uppercase text-xs text-semibold">VIEW BIO &gt;</Link>
                                                                ) : ''}
                                                            </div>
                                                        </div>
                                                        <div className="grid grid-cols-2 gap-4">
                                                            {featAgent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_EMAIL) && (
                                                                <div className="col-span-1">
                                                                    <ButtonInverted
                                                                        title="EMAIL ME"
                                                                        onClick={() => emailMe(featAgent)}
                                                                    />
                                                                </div>
                                                            )}
                                                            {featAgent.agentConnections.some((x) => x.contactMethod === CONTACT_METHOD_PHONE) && (
                                                                <div className="col-span-1">
                                                                    <ButtonInverted
                                                                        title="CALL ME"
                                                                        onClick={() => callMe(featAgent, () => {
                                                                            setSelectedAgent(featAgent);
                                                                            setIsPhoneFormModalOpen(true);
                                                                        })}
                                                                    />
                                                                </div>
                                                            )}
                                                            {/*if agent id is not found use generic approver email*/}
                                                            {featAgent.agentId === 0 ? (
                                                                <>
                                                                    <div className="col-span-1">
                                                                        <ButtonInverted
                                                                            title="EMAIL ME"
                                                                            onClick={() => emailMe(featAgent)}
                                                                        />
                                                                    </div>
                                                                    <div className="col-span-1">
                                                                        <ButtonInverted
                                                                            title="CALL ME"
                                                                            onClick={() => callMe(featAgent, () => {
                                                                                setSelectedAgent(featAgent);
                                                                                setIsPhoneFormModalOpen(true);
                                                                            })}
                                                                        />
                                                                    </div>
                                                                </>
                                                            ) : ''}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    </div>
                                </div>
                            </div>
                        <div className="w-full bg-footer-slate md:py-20 p-4 md:px-40 relative z-20">
                            {blogCategory && (
                                <>
                                    <h2 className="text-2xl md:text-5xl text-white text-center block pb-6 w-full uppercase">
                                        Explore more
                                        {' '}
                                        {blogCategory.categoryName}
                                        {' '}
                                        Articles
                                    </h2>
                                    <div className="grid overflow-hidden grid-cols-3 md:grid-cols-5 gap-1 mb-4">
                                        {data.categories.map((x, index) => (
                                            <div key={index}>
                                                <Link className={tagCss} to={`/travelbetter_category?category=C~${x.categoryName}`}>
                                                    {x.categoryName}
                                                </Link>
                                            </div>
                                        ))}
                                        {data.destinations.map((x, index) => (
                                            <div key={index}>
                                                <Link className={tagCss} to={`/travelbetter_category?category=D~${x.destination1}`}>
                                                    {x.destination1}
                                                </Link>
                                            </div>
                                        ))}
                                        {data.interests.map((x, index) => (
                                            <div key={index}>
                                                <Link className={tagCss} to={`/travelbetter_category?category=I~${x.interest1}`}>
                                                    {x.interest1}
                                                </Link>
                                            </div>
                                        ))}
                                        {data.suppliers.map((x, index) => (
                                            <div key={index}>
                                                <Link className={tagCss} to={`/travelbetter_category?category=S~${x.supplierName}`}>
                                                    {x.supplierName}
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                    <ArticlesList
                                        ArticlesCategory={blogCategory.categoryName}
                                        ArticlesType="C"
                                        ArticlesReturned="4"
                                        ArticlesDisplayTitle={false}
                                        ArticleDisplayTeaser={false}
                                        ArticleOrder="random"
                                    />
                                </>
                            )}
                        </div>
                             <div className="relative z-20 md:-mt-8">
                                <WhyComponent displayStats="hidden" />
                             </div>
                            <div className="relative z-20 bg-white h-8 md:h-0" />
                        </div>
                    </>
                )}
            {(!data && loading === false && document.readyState === 'complete') && (
                <div className="mx-4 md:mx-20 py-20 text-center min-h-screen">
                    <h2 className="tl-cyan uppercase text-4xl tracking-wide">Sorry, this article is gone or the url is invalid</h2>
                    <div>
                        <h3 className="uppercase text-xl my-2 font-semibold tracking-wide">
                            It looks like the article you have selected is no longer available.
                            <br />
                            <a href="/travelbetter" className="tl-red hover:text-black ">Don’t worry, we have plenty of other interesting and informative articles to choose from</a>
                        </h3>
                    </div>
                </div>
            )}
            <Modal
                isOpen={isEmailFormModalOpen || isPhoneFormModalOpen}
                onRequestClose={() => closeModal()}
                style={modalCustomStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <div className="z-50">
                    <div className="flex justify-end cursor-pointer" onClick={() => closeModal()}>
                        <IoClose className="text-red-500 text-right" />
                    </div>
                    {isEmailFormModalOpen && <EmailForm agent={selectedAgent} blog={data} />}
                    {isPhoneFormModalOpen && (
                        <PhoneForm
                            agent={selectedAgent}
                            phone={selectedAgent.agentId === 0 ? selectedAgent.hostAgencyPhone : selectedAgent.agentConnections.find((x) => x.contactMethod === 'by Phone')?.contactValue}
                            callBack={() => {
                                setIsPhoneFormModalOpen(false);
                                setIsEmailFormModalOpen(true);
                            }}
                        />
                    )}
                </div>
            </Modal>
            {loading && <Loading />}
        </>
    );
}

export default Blog;
