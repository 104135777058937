import React, { useEffect, useState } from 'react';
import { IoChevronBack } from 'react-icons/io5';
import StarRatings from 'react-star-ratings';
import parser from 'html-react-parser';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-slideshow-image/dist/styles.css';
import sanitizeHtml from 'sanitize-html-react';

import {
    getStateName,
} from '../utils';
import API from '../services/API';
import Loading from '../components/elements/Loading';
import 'react-toastify/dist/ReactToastify.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import AgentLabel from '../components/AgentLabel';
import BreadCrumb from '../components/BreadCrumb';

function Itineraries() {
    const { id } = useParams();
    const [agent, setAgent] = useState();
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();

    const [previewMode, setPreviewMode] = useState(query.get('preview') || false);
    const [links, setLinks] = useState([]);
    const [itineraries, setItineraries] = useState([]);
    const wrapperClass = 'md:-mx-20 -mx-2 md:px-20 pt-8 pb-4 flex flex-col';
    const getAgentBio = () => {
        setLoading(true);
        API.agentBio({
            agentId: id,
            agentPreview: previewMode,

        }).then((result) => {
            if (result.responseStatus === 1) {
                if (result.data.agentId !== 0) {
                    setAgent(result.data);
                    getItineraries();
                }
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    const getItineraries = () => {
        setLoading(true);
        API.agentItineraries({
            agentId: id,
            bioId: 0,
            returnAll: 'true',
            preview: 'false',

        }).then((result) => {
            if (result.responseStatus === 1) {
                setItineraries(result.data);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    useEffect(() => {
        getAgentBio();
    }, []);

    useEffect(() => {
        if (agent) {
            setLinks([{
                label: 'Home',
                url: '/',
            },
            {
                label: 'Find a travel Agent',
                url: '/find_travel_agent',
            }, {
                label: getStateName(agent.state),
                url: `/agents?state=${agent.state}`,
            },
            {
                label: `${agent.firstName} ${agent.lastName}`,
                url: `/agent/${id}`,
            },
            {
                label: 'All Itineraries',
            },
            ]);
        }
    }, [agent]);

    const agentBio = () => (
        <div className="flex flex-col gap-4">
            <BreadCrumb links={links} />
            <div className="text-gray-500 text-sm font-semibold flex flex-row items-center cursor-pointer gap-1" onClick={() => history.push(`/agent/${id}`)}>
                <IoChevronBack className=" text-right" />
                <div>
                    {' '}
                  Return to My Main profile
                </div>
            </div>
            <AgentLabel agent={agent} showReview={false}/>
        </div>
    );

    const agentItineraries = () => (
        <div>
            {itineraries.length > 0 && (
                <div className={[wrapperClass, 'bg-white'].join(' ')}>
                    <div className="tl-cyan text-center md:text-left text-3xl px-4 uppercase">All My Itineraries</div>
                    <div className="grid grid-cols-1  md:grid-cols-3  gap-4 mt-10">
                        {itineraries.map((x) => (
                            <div
                                className="flex flex-col gap-4 px-4  cursor-pointer"
                                onClick={() => history.push(`/agent/${agent.agentId}/itinerary/${x.itineraryId}`)}
                                key={x.itineraryId}
                            >
                                <div className="hover:scale-105 transition transform  ease-in-out duration-500">
                                    <img src={x.itineraryImage} className="w-96" alt={x.title} loading="lazy" />
                                    {' '}
                                </div>
                                <div className="text-xl font-semibold uppercase">{x.title}</div>
                                <div className="">
                                    <div className="flex float-left w-8 h-8 bg-cyan rounded-full text-center text-white justify-center items-center">
                                        <i className="fa fa-globe" aria-hidden="true" />
                                    </div>
                                    <div className="float-left pl-4">
                                        <div className="uppercase tl-cyan font-semibold -mt-1">Countries visited</div>
                                        <div className="-mt-1">
                                            {x.visitedCountries}
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="flex float-left w-8 h-8 bg-cyan rounded-full text-center text-white justify-center items-center">
                                        <i className="fa fa-calendar" aria-hidden="true" />
                                    </div>
                                    <div className="float-left pl-4">
                                        <div className="uppercase tl-cyan font-semibold -mt-1">Suggested Duration</div>
                                        <div className="-mt-1">
                                            {x.itineraryDays.length && (
                                                <>
                                                    {`${x.itineraryDays.length} Days`}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="text-base"
                                >
                                    {x.overview.length > 350 ? parser(`${sanitizeHtml(x.overview.slice(0, 350))}...<br/><span class="float-right md:float-none tl-red cursor-pointer font-semibold uppercase hover:text-black">Read more <i class="fa fa-caret-right" aria-hidden="true" /></span>`)
                                        : parser(`${sanitizeHtml(x.overview)}...<br/><span class="float-right md:float-none tl-red cursor-pointer font-semibold uppercase hover:text-black">Read more <i class="fa fa-caret-right" aria-hidden="true" /></span>`)}
                                </div>
                                <div />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
    return (
        <div className=" max-w-screen-2xl mx-auto">
            <ToastContainer />
            {agent && (
                <div className="h-full  flex flex-col pt-24 md:pt-12 px-4 md:px-10 mx-auto">
                    <div className="md:mx-10">
                        {agentBio()}
                    </div>
                    {agentItineraries()}
                </div>
            )}
            {loading && <Loading />}
        </div>
    );
}

export default Itineraries;
