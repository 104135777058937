import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Iframe from 'react-iframe';
import Cookies from 'universal-cookie';
import API from '../services/API';
import Hero from '../components/Hero';
import BreadCrumb from '../components/BreadCrumb';

function AgencyLocator() {
    const [locale, setLocale] = useState();
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const myIp = query.get('ip') || null;
    const userLocationCookie = new Cookies();

    const getMatchedAgentsUserLocation = async () => {
        API.getIpLocation({
            myIp,
        }).then((ipResult) => {
            if (ipResult.responseStatus === 1) {
                /*SET THE COOKIE*/
                userLocationCookie.set('userLocation', JSON.stringify(ipResult.data),
                    {
                        expires: new Date(Date.now() + (60 * 60 * 24 * 1000 * 7)),
                        path: '/',
                        sameSite: 'lax',
                    });
                /*GET CLOSEST AGENTS*/
                setLocale(ipResult.data.country_code);
            } else {
                toast(ipResult.error.message);
            }
        });
    };

    useEffect(() => {
        if ((!userLocationCookie.get('userLocation')) || (myIp !== null)) {
            getMatchedAgentsUserLocation();
        } else if ((userLocationCookie.get('userLocation')) && (myIp === null)) {
            setLocale(userLocationCookie.get('userLocation').country_code);
        }
    }, []);

    // handle <a href=tel../> clicks from the agency locator iframe
    useEffect(() => {
        // Function to handle the message
        const handleTelLink = (event) => {
            const allowedOrigins = ['https://www.travelleaders.com', 'http://dev.travelleaders.com', 'https://qa.travelleaders.com', 'http://localhost'];
            // Check if the event origin is in the list of allowed origins
            if (!allowedOrigins.includes(event.origin)) {
                //console.log('Blocked origin:', event.origin);
                return;
            }
            if (event.data.type === 'openTel') {
                window.location.href = event.data.href; // This changes the location of the parent window
            }
        };
        window.addEventListener('message', handleTelLink);
        // Cleanup function to remove the event listener when component unmounts
        return () => {
            window.removeEventListener('message', handleTelLink);
        };
    }, []);

    const links = [{
        label: 'Home',
        url: '/',
    },
    {
        label: 'Agency Locator',
        url: '',
        }];

    return (
        <>
            <Helmet>
                <title>Find a Travel Agency near you  | Travel Leaders</title>
                <meta name="title" content="Find a Travel Agency near you  | Travel Leaders" />
                <meta name="description" content="Locate a Travel Leaders Office in your area via your address, zip or city" />
            </Helmet>
            <Hero title="Locate an Agency" imageFile="hero-map-search.webp" additionalCSS="pt-0 -mb-12" />
            <div className=" max-w-screen-2xl mx-auto">
                <div className="min-h-screen">
                    <div className="mx-4 md:mx-20 pb-10 text-center">
                        <div className="">
                            <BreadCrumb links={links} />
                        </div>
                        <Iframe
                            url={`/StoreLocator/AgencyLocator.aspx?lcl=${locale === '-' ? 'US' : locale}`}
                            width="100%"
                            height="100%"
                            id="agencyMap"
                            className="w-100 agencyLocatorFrame"
                            display="block"
                            position="relative"
                            allowFullScreen="false"
                            scrolling="no"
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default AgencyLocator;
